<template>
  <div>
    <div class="content-box" style="padding-top: 24px">
      <div class="content-main">
        <ayl-table :table="table"></ayl-table>
        <div class="tac mt40px">
          <el-button type="danger" class="btn-danger" style="margin-left: 10px" @click="onClose">关闭</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    dataFromDialog: {
      //从弹窗获得传来的参数等数据，！！！！名称固定！！！！
      type: Object,
      default() {
        return {};
      }
    }
  },
  data() {
    const vm = this;
    return {
      nav: [{ name: "匹配人员" }],
      table: {
        api: vm.$api_hw.personnelInfo_personListMatchPersonDevice,
        query: {
          queryContent: null
        },
        // 是否显示分页
        // hidePagination: true,
        // 表格查询的条件组件
        searchData: [
          {
            type: "input",
            model: "queryContent",
            placeholder: "设备号"
          }
        ],
        columns: [
          {
            title: "序号",
            width: "50px",
            $index: "index"
          },
          {
            title: "设备ID",
            width: "180px",
            key: "deviceName"
          },
          {
            title: "SIM卡号",
            key: "simCardNumber"
          },
          {
            title: "应用对象",
            width: "100px",
            key: "objectTypeDesc"
          },
          {
            title: "所属企业",
            key: "companyName"
          },
          {
            title: "操作",
            width: "215px",
            render(h, ctx) {
              return h("span", [
                h(
                  "span",
                  {
                    class: {
                      "table-view-hw": true
                    },
                    on: {
                      click: vm.confirmMatch.bind(this, ctx.row)
                    }
                  },
                  "确认匹配"
                )
              ]);
            }
          }
        ]
      }
    };
  },
  methods: {
    confirmMatch(val) {
      this.$confirm("确认匹配SIM卡号？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        this.$api_hw
          .personnelInfo_personBindDevice({
            deviceId: val.deviceId,
            id: this.dataFromDialog.id
          })
          .then(res => {
            this.$message({
              type: "success",
              message: "匹配成功!"
            });
            this.$search(this.table);
          });
      });
    },
    //关闭
    onClose() {
      BUS.$emit(BUSEVENT.REFRESH_PERSONNEL_INFO);
      this.$emit("onClose"); //关闭弹窗
    }
  },
  async mounted() {
    await this.$search(this.table);
  }
};
</script>

<style lang='sass' scoped>
.btn
  width: 64px
  height: 28px
  border: 1px solid rgba(26,188,156,1)
  border-radius: 4px
  color: #1ABC9C
  
  .drawer-main
    margin: 5px 15px
    .drawer-btn
      width: 92px
      height: 36px
      margin-bottom: 10px
      background: rgba(237,237,237,1)
      border-radius: 2px
      outline: none
      border: 0
      &:hover
        margin-bottom: 10px
        background: rgba(26,188,156,1)
        border-radius: 2px
        border: 0
        color: #fff
      &:before
        margin-bottom: 10px
        background: rgba(26,188,156,1)
        border-radius: 2px
        border: 0
        color: #fff
    .drawer-btn1
      width: 92px
      height: 36px
      margin-bottom: 10px
      background: rgba(26,188,156,1)
      border-radius: 2px
      outline: none
      border: 1px solid transparent
      color: #fff

  .drawer-contract
    margin: 30px 15px 10px
    .drawer-contract-item
      width: 100%
      .drawer-contract-span
        color: #2E3033
    .drawer-contract-item1
      width: 48%
      .drawer-contract-span
        color: #2E3033
  .drawer-hr
    width: 100%
    height: 1px
    background: rgba(232,232,232,1)
  .drawer-map
    margin: 20px 15px 10px
    .drawer-map-title
      color: rgba(46,48,51,1)
  /deep/.el-drawer__header 
      margin-bottom: 15px
      padding-left: 15px
      color: #222
      font-size: 16px
  /deep/.el-button--success 
      color: #FFFFFF
      background-color: rgba(26,188,156,1)
      border-color: rgba(26,188,156,1)

</style>
